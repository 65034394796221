@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Rubik', sans-serif;
}

@layer components {
  html,
  body {
    @apply bg-card;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  z-index: 20 !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.DateRangePicker_picker__fullScreenPortal_4 {
  z-index: 100;
}

.SingleDatePicker_picker {
  z-index: 100;
}
